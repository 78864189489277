export const FIXED = '.header_fixed';
export const BURGER_BOX = '#burger__box';
export const BURGER = '#btn__burger';
export const NAV = '#navigation__box';
export const SOCIAL_SELECTOR = '.social__box';
export const TOP_POSITION_CLASS = 'header_top-position';
export const ACTIVE_CLASS = '_active';
export const HIDE_SCROLL_BODY_CLASS = 'hide-scroll';
export const BURGER_MENU_OPENED = 'burger-opened';
export const MENU_WITH_CHILDREN = '.nav__item_has-child';
export const SUB_MENU_BTN_CLASS = 'sub-menu__toggle';
export const SUB_MENU_OPEN_CLASS = 'nav__item_open';
export const SUB_MENU_NAV_HAS_SCROLL = 'sub-menu__nav--has-scroll';
export const SUB_MENU_LIST = '.sub-menu__nav';
export const SUB_MENU_ITEM = '.sub-menu__item';
export const SUB_MENU_SCROLL = '.nav-item__native-scroll';
export const COLLAPSED_CLASS = 'header_collapsed';
export const HEADER_WRAPPER = '.header__wrap';
export const HEADER_LAYOUT_WRAPPER = '.header__wrap > .header-layout-wrapper';
export const WITHOUT_BURGER_CLASS = 's-wrapper_original';
export const HEADER_ELEMENT_WRAPPER = '.s-wrapper';
export const WITH_BURGER_CLASS = 's-wrapper_collapsed';
export const NAV_BOX_STICKY = '.nav__box-wrap--sticky';
export const NAV_BOX_WRAP = '.nav__box-wrap--sticky > .s-wrapper';
export const CART_CLASS = 'header-cart';
export const LOGO_CLASS = 'header-logo';
export const LOGO_TEXT_CLASS = '.logo__text';
export const CROP_CLASS = '.crop';
export const CROP_CONTENT_CLASS = '.crop__content';
export const HEADER_CLASS = '.header';
export const MODE_COVER_CLASS = '.mode_cover';
export const NAV_BOX_CLASS = '.nav__box';
export const HEADER_NAV = '.header-nav';
export const S_WRAPPER_NAV_CLASS = '.s-wrapper_nav';
export const FIXED_TEMPLATE = '.template__fixed-header';
export const MAIN_HEADER = '.main__header';
export const TOPBAR = '.topbar__header';
export const SUBHEADER = '.subheader__header';
export const FIRST_SECTION_BG_SELECTOR = '.main > .s-section:first-of-type > div > .section_bg';
export const FIRST_VIDEO_BG_SELECTOR = '.main > .s-section:first-of-type > div > .video-full > .video-bg';
export const FIRST_MAP_BG_SELECTOR = '.main > .s-section:first-of-type > div > .map-full > .map-bg';
export const HEADER_TYPES = {
  MAIN: 'header',
  TOPBAR: 'topbar',
  SUBHEADER: 'subheader',
};
export const LEFT = 'left';
export const RIGHT = 'right';
export const CENTER = 'center';
